
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { IonicVue } from '@ionic/vue';
import piniaPluginPersistedState from "pinia-plugin-persistedstate"
import App from './App.vue'
import router from './router'
import './assets/main.scss'
import Vapor from 'laravel-vapor'
import * as Sentry from "@sentry/vue";
import VueGtag from "vue-gtag"

// import Pusher from 'pusher-js'

// Enable pusher logging - if in dev mode
// if (import.meta.env.MODE === 'development') {
//   Pusher.logToConsole = true;
// }

Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL);
window.Vapor = Vapor;


const pinia = createPinia()
pinia.use(piniaPluginPersistedState)

const app = createApp(App)
app.mixin({
  methods: {
    asset: Vapor.asset
  }
})

// Sentry.init({
//   app,
//   dsn: "https://a7856108d1e201a1ade2b326ebd1ef86@o4506616968773632.ingest.sentry.io/4506769909350400",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration({
//       maskAllText: false,
//       blockAllMedia: false,
//     }),
//   ],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,

//   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/app.parbeta.club/],

//   // Capture Replay for 10% of all sessions,
//   // plus for 100% of sessions with an error
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// });

app.use(IonicVue)
  .use(pinia)
  .use(router);

// Initialize Google Analytics with VueGtag
if (import.meta.env.VITE_GA_MEASUREMENT_ID) {
  app.use(VueGtag, {
    config: {
      id: import.meta.env.VITE_GA_MEASUREMENT_ID,
      params: {
        anonymize_ip: true,
      },
    },
    pageTrackerScreenviewEnabled: true,
  }, router);
}

// Mount the app
app.mount('#app');